import styled, {css} from "styled-components";


export const StyledCardContainer = styled.div`
  ${({theme}) => css`
   padding: 24px 32px 32px 32px;
    border-radius: 10px;
    max-width: 386px;
    min-height: 268px;
    background-color: ${theme.palette.neutral[100]};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    gap: 14px;
    cursor: default;
    ${theme.mediaQueries.mUp} {
      padding: 24px 32px 32px;
    }
    small {
      ${theme.typography.small};
      padding-bottom: 18px;
    }

    h5 {
      ${theme.typography.heading5};
    }

    h1 {
      ${theme.typography.heading6};
      background: ${theme.linearGradient[20]};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    
    button {
      &:hover {
        background: ${theme.linearGradient[30]};
      }
    }
    
    &:hover {
      box-shadow: ${theme.shadows[20]};
    }


  `}
`;
