export const SuccessIcon = ({
                                stroke = '#18A957',
                                fill = 'none',
                                ...props
                            }) => (
    <svg {...props} viewBox="0 0 24 24">
        <circle
            cx="12"
            cy="12"
            r="11"
            fill={fill}
            stroke={stroke}
            strokeWidth="2"
        />

        <path
            stroke={stroke}
            fill={fill}
            strokeLinecap="round"
            strokeWidth="2"
            d="M9 11.95l2.121 2.121 4.243-4.243"
        />
    </svg>
)
export const AlertIcon = ({ stroke = '#DF1642', fill = 'none', ...props }) => (
    <svg {...props} viewBox="0 0 24 24">
        <circle
            cx="12"
            cy="12"
            r="11"
            stroke={stroke}
            fill={fill}
            strokeWidth="2"
        />
        <path
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 7v5M12 16v.5"
        />
    </svg>
)

export const CloseIcon = ({...props}) => (
    <svg
        {...props}
        width="50"
        height="50"
        viewBox="0 0 50 50"
    >
        <path d="M7.719 6.281L6.28 7.72 23.563 25 6.28 42.281 7.72 43.72 25 26.437 42.281 43.72l1.438-1.438L26.437 25 43.72 7.719 42.28 6.28 25 23.563z"/>
    </svg>
)

export const LogoKrominLabs = ({...props}) => (
    <svg
        {...props}
        width="101"
        height="36"
        fill="none"
        viewBox="0 0 101 36"
    >
        <path
            fill="#1D0F41"
            d="M12.802 26l-4.967-6.396 4.87-5.305h-3.15l-5.16 5.644V9.841H1.851V26h2.544v-3.15l1.623-1.671L9.604 26h3.198zm3.702 0v-7.97c.509-.824 1.938-1.551 3.004-1.551.315 0 .581.024.8.073v-2.52c-1.527 0-2.932.872-3.804 1.987v-1.72H13.96V26h2.544zm10.493.29c3.755 0 6.032-2.785 6.032-6.153 0-3.343-2.277-6.13-6.032-6.13-3.707 0-6.008 2.787-6.008 6.13 0 3.368 2.301 6.154 6.008 6.154zm0-2.252c-2.156 0-3.368-1.817-3.368-3.9 0-2.06 1.212-3.877 3.368-3.877 2.18 0 3.392 1.817 3.392 3.876 0 2.084-1.212 3.9-3.392 3.9zM51.846 26v-8.455c0-2.374-1.211-3.537-3.368-3.537-1.744 0-3.294 1.09-3.973 2.156-.388-1.284-1.454-2.156-3.198-2.156-1.768 0-3.319 1.163-3.803 1.89v-1.6H34.96V26h2.544v-8.164c.533-.776 1.574-1.575 2.689-1.575 1.38 0 1.938.848 1.938 2.108V26h2.544v-8.189c.509-.75 1.575-1.55 2.713-1.55 1.357 0 1.914.848 1.914 2.108V26h2.544zm3.911-13.64c.872 0 1.575-.702 1.575-1.574 0-.873-.703-1.575-1.575-1.575-.848 0-1.575.702-1.575 1.575 0 .872.727 1.574 1.575 1.574zM57.041 26V14.299h-2.544V26h2.544zm13.098 0v-8.237c0-2.399-1.26-3.755-3.779-3.755-1.841 0-3.343.969-4.119 1.89v-1.6h-2.543V26h2.543v-8.164c.582-.8 1.696-1.575 3.005-1.575 1.429 0 2.35.605 2.35 2.422V26h2.543z"
        ></path>
        <path
            fill="url(#paint0_linear_15_99)"
            d="M75.58 29V18.328H73.9V29h1.68zm7.937 0v-5.12c0-2.08-1.504-2.8-3.216-2.8-1.232 0-2.352.4-3.248 1.248l.688 1.168c.672-.656 1.424-.976 2.288-.976 1.072 0 1.808.544 1.808 1.44v1.152c-.56-.656-1.456-.992-2.496-.992-1.264 0-2.688.736-2.688 2.512 0 1.712 1.424 2.56 2.688 2.56 1.024 0 1.92-.368 2.496-1.024V29h1.68zm-1.68-1.76c-.384.528-1.104.8-1.84.8-.944 0-1.648-.544-1.648-1.376 0-.848.704-1.392 1.648-1.392.736 0 1.456.272 1.84.8v1.168zM86.73 29v-1.056c.592.768 1.472 1.248 2.48 1.248 1.968 0 3.424-1.504 3.424-4.048 0-2.496-1.44-4.064-3.424-4.064-.976 0-1.872.448-2.48 1.264v-4.016h-1.68V29h1.68zm0-5.376c.368-.576 1.2-1.056 1.984-1.056 1.344 0 2.192 1.072 2.192 2.576s-.848 2.56-2.192 2.56c-.784 0-1.616-.448-1.984-1.024v-3.056zm6.302 4.368c.8.784 2 1.2 3.312 1.2 2.08 0 3.216-1.04 3.216-2.4 0-1.824-1.648-2.208-2.992-2.496-.912-.208-1.68-.416-1.68-1.008 0-.544.56-.864 1.44-.864.96 0 1.824.4 2.288.928l.704-1.184c-.704-.624-1.696-1.088-3.008-1.088-1.952 0-3.04 1.088-3.04 2.352 0 1.728 1.584 2.08 2.912 2.368.944.208 1.76.448 1.76 1.12 0 .576-.512.944-1.52.944s-2.08-.528-2.624-1.088l-.768 1.216z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_15_99"
                x1="72.488"
                x2="72.488"
                y1="12"
                y2="36"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#A944F5"></stop>
                <stop offset="1" stopColor="#9938F2"></stop>
            </linearGradient>
        </defs>
    </svg>
)


export const UploadIcon = ({width = 24, height = 28, ...props}) => (
    <svg
        {...props}
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 28"
    >
        <path fill="#EAEEF5" d="M24 22h-4v-4h-2v4h-4v2h4v4h2v-4h4v-2z"/>
        <path
            fill="#EAEEF5"
            d="M10 26H2V2h8v6a2.006 2.006 0 002 2h6v4h2V8a.91.91 0 00-.3-.7l-7-7A.908.908 0 0012 0H2a2.006 2.006 0 00-2 2v24a2.006 2.006 0 002 2h8v-2zm2-23.6L17.6 8H12V2.4z"
        />
    </svg>
)

export const DeleteItemIcon = ({...props}) => (
    <svg
        {...props}
        width="11"
        height="12"
        fill="none"
        viewBox="0 0 11 12"
    >
        <path
            fill="#EB5757"
            d="M10.313 2.12H8.124v-.438A1.312 1.312 0 006.812.369H4.188a1.312 1.312 0 00-1.312 1.313v.437H.687a.437.437 0 100 .875h.438v7.875a.875.875 0 00.875.875h7a.875.875 0 00.875-.875V2.994h.438a.438.438 0 000-.875zM3.75 1.681a.437.437 0 01.438-.438h2.625a.437.437 0 01.437.438v.437h-3.5v-.437zM9 10.869H2V2.994h7v7.875zM4.625 5.182v3.5a.437.437 0 11-.875 0v-3.5a.437.437 0 11.875 0zm2.625 0v3.5a.437.437 0 11-.875 0v-3.5a.437.437 0 11.875 0z"
        />
    </svg>
)



export const SendMessageIcon = ({...props}) => (
    <svg
        {...props}
        width="19"
        height="16"
        fill="none"
        viewBox="0 0 19 16"
    >
        <path
            fill="#fff"
            d="M1.757 15.91c-.355.142-.692.111-1.012-.093-.32-.205-.48-.502-.48-.892v-3.97c0-.248.072-.47.214-.665a.941.941 0 01.586-.373L8.789 8 1.065 6.081a.941.941 0 01-.586-.373 1.104 1.104 0 01-.213-.665v-3.97c0-.39.16-.688.48-.892.319-.205.656-.236 1.011-.093l16.409 6.926c.444.195.666.523.666.985 0 .462-.222.79-.666.986L1.757 15.91z"
        />
    </svg>
)


export const InfoIcon = ({width = 16, fill = '#fff', ...props}) => (
    <svg
        {...props}
        width={width}
        height={width}
        fill="none"
        viewBox="0 0 16 15"
    >
        <path
            fill={fill}
            d="M7.25 11.25h1.5v-4.5h-1.5v4.5zm.75-6c.213 0 .39-.072.535-.216A.723.723 0 008.75 4.5a.726.726 0 00-.216-.535A.723.723 0 008 3.75a.726.726 0 00-.535.216.723.723 0 00-.215.534c0 .213.072.39.216.535A.723.723 0 008 5.25zM8 15a7.3 7.3 0 01-2.925-.591 7.585 7.585 0 01-2.381-1.603 7.569 7.569 0 01-1.603-2.381A7.316 7.316 0 01.5 7.5a7.3 7.3 0 01.591-2.925 7.586 7.586 0 011.603-2.381A7.569 7.569 0 015.075.59 7.316 7.316 0 018 0a7.3 7.3 0 012.925.591 7.586 7.586 0 012.381 1.603 7.579 7.579 0 011.604 2.381A7.29 7.29 0 0115.5 7.5a7.3 7.3 0 01-.591 2.925 7.586 7.586 0 01-1.603 2.381 7.58 7.58 0 01-2.381 1.604A7.29 7.29 0 018 15zm0-1.5c1.675 0 3.094-.581 4.256-1.744C13.42 10.594 14 9.175 14 7.5c0-1.675-.581-3.094-1.744-4.256C11.094 2.08 9.675 1.5 8 1.5c-1.675 0-3.094.581-4.256 1.744C2.58 4.406 2 5.825 2 7.5c0 1.675.581 3.094 1.744 4.256C4.906 12.92 6.325 13.5 8 13.5z"
        />
    </svg>
)

export const SourcesIcon = ({...props}) => (
    <svg
        {...props}
        width="12"
        height="15"
        fill="none"
        viewBox="0 0 12 15"
    >
        <path
            fill="#fff"
            d="M1.68.3A1.44 1.44 0 00.24 1.74v11.52a1.44 1.44 0 001.44 1.44h8.64a1.44 1.44 0 001.44-1.44V4.62L7.44.3H1.68zm0 1.44h5.04v3.6h3.6v7.92H1.68V1.74zM3.12 7.5v1.44h5.76V7.5H3.12zm0 2.88v1.44h3.6v-1.44h-3.6z"
        />
    </svg>
)

export const SegmentsIcon = ({width = 16, ...props}) => (
    <svg
        {...props}
        width={width}
        height="11"
        fill="none"
        viewBox="0 0 16 11"
    >
        <path
            fill="#fff"
            d="M14.667.5a.833.833 0 010 1.667H1.333a.833.833 0 010-1.667h13.334zM.5 9.667c0 .46.373.833.833.833h5.834a.833.833 0 000-1.667H1.333a.833.833 0 00-.833.834zM.5 5.5c0 .46.373.833.833.833h13.334a.833.833 0 000-1.666H1.333A.833.833 0 00.5 5.5z"
        />
    </svg>
)

export const ExpandIcon = ({width = 16, ...props}) => (
    <svg
        {...props}
        width="8"
        height="13"
        fill="none"
        viewBox="0 0 8 13"
    >
        <path
            fill="#F4F5F8"
            d="M.256 12.719A.83.83 0 010 12.11c0-.234.085-.436.256-.606l5.01-5.009L.238 1.47A.812.812 0 010 .872.84.84 0 01.256.256.83.83 0 01.864 0c.233 0 .436.085.606.256l5.744 5.761a.604.604 0 01.146.223.74.74 0 01.042.256.751.751 0 01-.043.256.607.607 0 01-.145.223l-5.76 5.76a.802.802 0 01-.591.24.828.828 0 01-.607-.256z"
        ></path>
    </svg>
)

export const ReloadIcon = ({width = 22, ...props}) => (
    <svg
        width={width}
        height={width}
        fill="none"
        viewBox="0 0 22 22"
        {...props}
    >
        <path
            stroke="red"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M9.842 13.316H6.947v2.895m5.211-7.527h2.895V5.79M6.706 9.265a4.632 4.632 0 018.297-.595m.292 4.065a4.632 4.632 0 01-8.298.595"
        />
        <rect
            width="20.5"
            height="20.5"
            x="0.75"
            y="0.75"
            stroke="red"
            strokeWidth="1.5"
            rx="10.25"
        />
    </svg>
)


export const AlertSideBarIcon = ({width = 16, ...props}) => (
    <svg
        width={width}
        height={width}
        fill="none"
        viewBox="0 0 16 15"
        {...props}
    >
        <path
            fill="#fff"
            d="M1.044 14.25a.734.734 0 01-.637-.375.828.828 0 01-.104-.366.666.666 0 01.103-.384l6.938-12a.737.737 0 01.29-.281A.777.777 0 018 .75c.125 0 .247.031.366.094a.732.732 0 01.29.281l6.938 12c.075.125.11.253.103.385a.81.81 0 01-.103.365.733.733 0 01-.263.272.698.698 0 01-.375.103H1.044zm1.293-1.5h11.325L8 3l-5.663 9.75zM8 12c.212 0 .39-.072.535-.216a.723.723 0 00.215-.534.726.726 0 00-.216-.535A.723.723 0 008 10.5a.726.726 0 00-.535.216.723.723 0 00-.215.534c0 .213.072.39.216.535A.723.723 0 008 12zm0-2.25c.212 0 .39-.072.535-.216A.723.723 0 008.75 9V6.75a.726.726 0 00-.216-.535A.723.723 0 008 6a.726.726 0 00-.535.216.723.723 0 00-.215.534V9c0 .213.072.39.216.535A.723.723 0 008 9.75z"
        ></path>
    </svg>
)