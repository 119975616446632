import React from 'react';
import {StyledLoader} from "./styles";
const ChatBotLoader = ({colorWhite}) => {


    return <>
        <StyledLoader colorWhite={colorWhite}/>
    </>
}

export default ChatBotLoader;