import styled, {css} from "styled-components";

export const StyledWrapperSidebarMobile = styled.div`
  ${({theme, openSideBar}) => css`
    width: ${openSideBar ? '93%' : '0'};
    height: 100vh;
    background-color: ${theme.palette.secondary.base.hover};
    position: fixed;
    top: 0;
    left: ${openSideBar ? '0' : '-80%'};
    transition: left 0.5s ease-in-out;
    z-index: 100;
    padding: 24px 16px;
  `}
`;


export const StyledWrapperSidebarDesktop = styled.div`
  ${({theme, openSideBar, dataNotExists}) => css`
    width: 16px;
    height: 100vh;
    background-color: ${theme.palette.secondary.base.hover};
    display: flex;
    align-items: center;
    flex-direction: column;
    animation: pulse 1.3s 2;
    cursor: default;
    @keyframes pulse {
      0% {
        width: 16px;
      }
      50% {
        width: 50px;
      }
      100% {
        width: 16px;
      }
    }

    ${openSideBar && css`
      width: 414px;
      justify-content: ${dataNotExists ? 'center' : 'flex-start'};
      padding: 24px;
      transition: width 0.5s ease-in-out;
    `};

    ${!openSideBar && css`
      transition: width 0.5s ease-out;
    `}

  `}
`;


export const StyledContainerArrow = styled.div`
  ${({theme, openSideBar, isMobile}) => css`
    position: fixed;
    cursor: pointer;
    width: 16px;
    height: 34px;
    left: ${isMobile ? 0 : '16px'};
    top: 50%;
    background-color: ${theme.palette.secondary.base.hover};
    border-radius: 0 4px 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    ${openSideBar && css`
      left: ${isMobile ? '92%' : '414px'};
      transition: left 0.5s ease-in-out;
    `};

    ${!openSideBar && css`
      transition: left 0.5s ease-out;
    `}
    svg {
      transform: ${openSideBar ? 'rotate(180deg)' : 'rotate(0deg)'};
    }
  `}
`;


export const StyledContainerH3 = styled.h3`
  ${({theme}) => css`
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;

    letter-spacing: 1px;
    color: ${theme.palette.neutral.white};
  `}
`;

export const StyledContainerParagraph = styled.div`
  ${({openSideBar, dataNotExists}) => css`
    opacity: ${openSideBar ? '1' : '0'};
    transition: opacity 1s ease-in-out;
    animation: ${dataNotExists ? openSideBar && 'show 3s ease-in-out' : openSideBar && 'show 1s ease-in-out'};
    @keyframes show {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `}
`;


