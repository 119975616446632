import ChatBotMessage from "../chatbotmessage/ChatBotMessage";
import {ChatBotBodyContainer, StyledContainerMessageBody} from "./styles";
import ChatBotLoader from "../chatbotLoader/ChatBotLoader";
import {StyledContainerMessage, StyledContainerMessageError, StyledDot, StyledMessage} from "../chatbotmessage/styles";
import {ReloadIcon} from "../../../assets/icons/icons";
import {httpFetchResponse} from "../../../http/chatbot";
import {handleApiError} from "../../../utilities/helpers";
import {useParams} from "react-router-dom";
import useError from "../../../hooks/useError";
import {useMemo} from "react";

const ChatBotBody = ({
                         haveTransition,
                         chatBotMessages,
                         allMessages,
                         setAllMessages,
                         setChatBotLoading,
                         chatBotLoading,
                         openSideBar,
                         handleOpenSideBarFromInfo,
                         selectedMessageID,
                         draft,
                         chatBodyContainerRef,
                         errorTooManyRequests,
                         setErrorTooManyRequests,
                         setDraft,
                         setLastMessage,
                     }) => {
    const {uuid} = useParams()
    const showError = useError()

    const sendMessage = async (message) => {
        if (chatBodyContainerRef?.current) chatBodyContainerRef.current.scrollIntoView({behavior: "smooth"})
        setDraft(message)
        try {
            setChatBotLoading(true)
            setErrorTooManyRequests(false)
            const {data} = await httpFetchResponse(uuid, message);
            setDraft(null)
            if (!data?.answer) {
                setLastMessage({
                    id: data.id,
                    question: data.question,
                    answer: data?.answer,
                })
            } else {
              console.log("dio => ", data)
                setAllMessages([
                  {
                    id: `${data.id}-bot`,
                    message: data.answer,
                    role: "assistant",
                  },
                  {
                    id: `${data.id}-user`,
                    message: data.question,
                    role: "user",
                  },
                  ...allMessages
                ])
            }

            if (chatBodyContainerRef.current) chatBodyContainerRef.current.scrollIntoView({behavior: "smooth"})
        } catch (error) {
            if (error?.response?.status === 500 || error?.response?.status === 429 || error?.response?.status === 502) {
                setErrorTooManyRequests(true)
                showError(`Error 429. Something went wrong, please click the reload button to try again`)
            } else {
                handleApiError({error, handleGeneralError: showError})
            }
        } finally {
            setChatBotLoading(false)
        }
    }

    const isNotKrominLogo = useMemo(() => {
        return uuid === "492f75fe-51dc-40a6-bf40-a5a060da9f8f"
    }, [uuid])

  console.log(allMessages)
    return <>
        <ChatBotBodyContainer isNotKrominLogo={isNotKrominLogo}>
            <StyledContainerMessageBody>
                {/*ref for scroll to bottom*/}
                <div ref={chatBodyContainerRef}/>

                {/*loading message*/}
                {chatBotLoading &&
                    <StyledContainerMessage isChatBot>
                        <StyledDot isChatBot/>
                        <StyledMessage isChatBot>
                            <ChatBotLoader/>
                        </StyledMessage>
                    </StyledContainerMessage>
                }

                {/*draft bot*/}
                {!chatBotLoading && chatBotMessages?.message &&
                    <StyledContainerMessage isChatBot>
                        <StyledDot isChatBot/>
                        <StyledMessage isChatBot>
                            {chatBotMessages?.message}
                        </StyledMessage>
                    </StyledContainerMessage>
                }

                {/*draft message for first message and set error*/}
                {draft && <StyledContainerMessageError>
                    {errorTooManyRequests && <ReloadIcon onClick={() => sendMessage(draft)}/>}
                    <ChatBotMessage
                        message={draft}
                        isChatBotAnswer={false}
                        errorTooManyRequests={errorTooManyRequests}
                    />
                </StyledContainerMessageError>}

                {/*all messages*/}
                {allMessages?.map((message) => {
                    return <ChatBotMessage
                        haveTransition={haveTransition}
                        chatBotMessages={chatBotMessages}
                        key={message?.id}
                        id={message?.id}
                        message={message.message}
                        isChatBotAnswer={message.role === "assistant"}
                        chatBotLoading={chatBotLoading}
                        openSideBar={openSideBar}
                        handleOpenSideBarFromInfo={handleOpenSideBarFromInfo}
                        selectedMessageID={selectedMessageID}
                        chatBodyContainerRef={chatBodyContainerRef}
                    />
                })}
            </StyledContainerMessageBody>
        </ChatBotBodyContainer>
    </>
}

export default ChatBotBody;