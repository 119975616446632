import styled, {css} from "styled-components";

const GhostVariant = ({theme, data, disabled, isLight}) => css`
  ${StyledButton};
  ${StyledSpan};
  background-color: transparent;
  color: ${theme.palette[data].base.background};
  border: 2px solid ${theme.palette[data][isLight ? 'light' : 'base'].background};
'&:hover': {
  background-color: ${theme.palette[data].light.background};
}

  ${!disabled && `
        background-color: none;
        color: ${theme.palette[data].dark.background};
        `}
  & svg {
    fill: ${!disabled && `${theme.palette[data].dark.background} !important`}
  }

  ${disabled && `
        border-color: ${theme.palette.neutral[100]};
        '&:hover': {
            border-color: ${theme.palette.neutral[100]}
        }
        & svg {
            fill: ${disabled && `${theme.palette.neutral[300]} !important`}
        }
    `}
`
const BorderlessVariant = ({theme, data, isLight, disabled}) => css`
  ${StyledButton};
  ${StyledSpan};
  background-color: ${isLight ? theme.palette[data][isLight ? 'light' : 'base'].background : 'unset'};
  border: 0;
  color: ${theme.palette[data].base.background} ${!disabled && `
        background-color: ${theme.palette[data].light.background};
        color: ${theme.palette[data].dark.background}
    `} & svg {
    fill: ${!disabled && `${theme.palette[data].dark.background} !important`}
  } ${disabled && `
        border-color: ${theme.palette.neutral[100]};
        '&:hover': {
            border-color: ${theme.palette.neutral[100]}
        }
        & svg {
            fill: ${disabled && `${theme.palette[data].base.background} !important`}
        }
    `}
`
const RoundVariant = ({theme, disabled, isLight, data}) => css`
  ${StyledButton};
  ${StyledSpan};
  border-radius: 50px;
  color: ${theme.palette.neutral[100]};
  background-color: ${theme.palette[data][isLight ? 'light' : 'base'].background};
  border: 2px solid ${theme.palette[data][isLight ? 'light' : 'base'].background};

  ${disabled && `
    background-color: ${theme.palette[data][isLight ? 'light' : 'base'].background} !important;
    opacity: 0.5;
  `}
  & svg {
    fill: ${disabled && `${theme.palette.neutral.white} !important`}
  }
`
const FilledVariant = ({theme, disabled, isLight, data}) => css`
  ${StyledButton} ;
  ${StyledSpan};
  color: ${theme.palette.neutral[100]};
  background-color: ${theme.palette[data][isLight ? 'light' : 'base'].background};
  border: 2px solid ${theme.palette[data][isLight ? 'light' : 'base'].background};
  ${disabled && `
    border-color: ${theme.palette.neutral[100]};
    '&:hover': {
        border-color: ${theme.palette.neutral[100]}
    }
    & svg {
        fill: ${disabled && `${theme.palette.neutral.white} !important`}
    }
`}
`

export const StyledButton = styled.button`
  ${({theme, collapsed, size, variant, disabled, data, width}) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    position: relative;
    ${!disabled && `
    cursor: pointer;
    &:hover {
        background-color: ${theme.palette[data].dark.background};
        border-color: ${theme.palette[data].dark.background};
      }   
  `}

    ${collapsed ? `
        height: ${theme.spacing * 6}px;
        width: ${width}px;
    ` : `
        width: ${width}px;
        aspect-ratio: unset;
        height: ${theme.spacing * 6}px;
    `}

    ${size === 'small' && `height: ${theme.spacing * 5}px;`}
    ${size === 'medium' && `height: ${theme.spacing * 6}px;`}
    ${size === 'large' && `height: ${theme.spacing * 7}px;`}


    ${variant === 'filled' && FilledVariant}

    ${variant === 'ghost' && GhostVariant}

    ${variant === 'borderless' && BorderlessVariant}

    ${variant === 'round' && RoundVariant}

    ${disabled && css`
      background-color: ${theme.palette[data].base.disabled};
      color: ${theme.palette.neutral.white};
      cursor: not-allowed;
    '&:hover': {
      background-color: ${theme.palette.neutral[100]};
      color: ${theme.palette.neutral[100]};
    },
    `}
  `}`;
export const StyledSpan = styled.span`
  ${({theme, icon, collapsed, iconPosition, size}) => {
    return css`
      display: grid;
      place-items: center;
      justify-content: center;
      white-space: nowrap;
      width: 100%;
      text-align: center;
      grid-template-columns: ${!!icon && !collapsed ? `${iconPosition === 'left' ? '24px min-content' : 'min-content 24px'}` : '1fr'};
      padding: ${theme.spacing * 2}px 0;
      grid-column-gap: 20px;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 2px;


      ${size === 'small' && `
          font-size: 14px;
      `}
      ${size === 'medium' && `
          font-size: 14px;
      `}
      ${size === 'large' && `
          font-size:20px;
      `}
      & svg {
        display: grid;
        align-items: center;
        justify-content: center;
        width: 22px;
      }
    `;
  }}
`

