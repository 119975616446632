import {Outlet} from 'react-router-dom'
import useAlert from "../../hooks/useAlert";
import Alert from "../../components/alert/Alert";
import {StyledContainerAlert} from "../../components/alert/styles";

const PublicLayout = props => {
    const {isAlertOpen, alertData, closeAlert} = useAlert()
    return <>

        <StyledContainerAlert>
            <Alert
                visible={isAlertOpen}
                onClose={closeAlert}
                variant={alertData.severity}
                title={alertData.title}
                canDismiss={alertData.canDismiss}
                haveIcon={alertData.haveIcon}
            />
        </StyledContainerAlert>
        <Outlet {...props} />
    </>
}

export default PublicLayout
