import styled, {css} from "styled-components";


export const StyledContainerAccuracy = styled.div`
  ${({theme, notRelevant}) => css`
    padding: 2px 8px;
    height: 22px;
    border: 1px solid ${notRelevant ? theme.palette.neutral[500] : theme.palette.neutral.white};
    border-radius: 2px;
  `}
`;

export const StyledContainerRelevantSeg = styled.div`
  direction: ltr;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 8px;
`;

export const StyledContainerSmall = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  margin-top: 8px;
  max-width: 350px;
  word-break: break-word;
  
`;

export const StyledDiv = styled.div`
  ${({header}) => css`
    padding: ${header ? '4px' : '8px 0'};
  `}
`;

export const StyledScrollableDiv = styled.div`
  ${({theme, haveAlert}) => {
    return css`
      max-height: calc(100vh - ${!haveAlert ? '440px' : '366px'});
      min-height: calc(100vh - ${!haveAlert ? '440px' : '366px'});
      overflow-y: scroll;
      overflow-x: hidden;
      position: relative;
      direction: rtl;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${theme.palette.neutral.white};
        border-radius: 54px;
      }

      &::-webkit-scrollbar-track {
        background-color: ${theme.palette.neutral[500]};
        border-radius: 54px;
      }

      ${theme.mediaQueries.mUp} {
        max-height: calc(100vh - ${!haveAlert ? '430px' : '360px'});
      }

      ${theme.mediaQueries.lUp} {
        max-height: calc(100vh - ${!haveAlert ? '430px' : '360px'});
      }
    `;
  }}
`;

export const StyledSegmentData = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 26px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
`;


export const StyledSegments = styled.div`
  ${({theme, notRelevant}) => css`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 2px;
    color: ${notRelevant ? theme.palette.neutral[500] : theme.palette.neutral.white};

    p {
      font-weight: 700;
      font-size: 12px;
    }

    small {
      font-weight: 400;
      font-size: 12px;
    }
  `}
`;

export const StyledContainerExpandSvg = styled.div`
  ${({isExpanded}) => css`
    cursor: pointer;
    & svg {
      width: 12px;
      height: 12px;
      transform: ${!isExpanded ? 'rotate(90deg)' : 'rotate(270deg)'};
      transition: transform 0.3s ease-in-out;
    }
  `}
`;