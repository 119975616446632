import { Navigate, Outlet } from 'react-router-dom'
import { ROUTE_CHATBOT } from '../../utilities/constants'
import config from "../../config";

const PublicRoute = () => {
    const accessToken = config.isAuthorized
    return accessToken ? <Navigate to={ROUTE_CHATBOT} /> : <Outlet />
}

export default PublicRoute
