import React, {useEffect, useRef, useState} from 'react';
import {StyledCol, StyledContainer} from "./styles";
import Sidebar from "../../components/sidebar/sidebar/Sidebar";
import ChatBotBody from "../../components/chatbot/chatbotBody/ChatBotBody";
import ChatBotFooter from "../../components/chatbot/chatbotFooter/ChatBotFooter";
import {httpGetDataSideBarAnalytics, httpGetDebugInfo, httpGetSessionId} from "../../http/chatbot";
import LocalStorageManager from "../../utilities/local-storage-manager";
import {handleApiError} from "../../utilities/helpers";
import useError from "../../hooks/useError";
import ResponseDocuments from "../../modelshttpResponse/ResponseDocuments";
import {useParams} from "react-router-dom";
import {socket} from "../../socket";
import useAlert from "../../hooks/useAlert";

export const statusSocket = {
    DEFAULT: 'default',
    IN_PROGRESS: 'in-progress',
    COMPLETED: 'completed',
    ERROR: 'error',
}
const ChatBot = () => {

    const [allMessages, setAllMessages] = useState([])
    const [chatBotLoading, setChatBotLoading] = useState(false);
    const [openSideBar, setOpenSideBar] = useState(false)
    const showError = useError()
    const [dataSideBar, setDataSideBar] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [draft, setDraft] = useState(null)
    const [selectedMessageID, setSelectedMessageID] = useState(null)
    const {uuid} = useParams()
    const chatBodyContainerRef = useRef(null);
    const [lastSeen, setLastSeen] = useState(null)
    const [errorTooManyRequests, setErrorTooManyRequests] = useState(false)
    const [chatBotMessages, setChatBotMessages] = useState(null)
    const [lastMessage, setLastMessage] = useState(null)
    const [status, setStatus] = useState(statusSocket.DEFAULT)
    const [haveTransition, setHaveTransition] = useState(false)
    const {triggerAlert} = useAlert()

    useEffect(() => {
        getSessionId()
    }, [])


    const getDataSideBar = async (id) => {
        const idSplit = id?.split('-')[0]
        if (!idSplit) return
        try {
            setIsLoading(true)
            const [debugInfoResponse, dataResponse] = await Promise.all([
                httpGetDebugInfo(uuid),
                httpGetDataSideBarAnalytics(uuid, idSplit),
            ])
            const {data: debugInfo} = debugInfoResponse
            const {data} = dataResponse
            const dataRemapped = ResponseDocuments(data, debugInfo)
            setDataSideBar(dataRemapped)
            setIsLoading(false)
        } catch (error) {
            handleApiError({error, handleGeneralError: showError})
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setOpenSideBar(selectedMessageID)
        getDataSideBar(selectedMessageID)
    }, [selectedMessageID])

    //handler info icon
    const handleOpenSideBarFromInfo = (id) => {
        setLastSeen(id)
        if (id === selectedMessageID) {
            setOpenSideBar(false)
            setSelectedMessageID(null)
        } else {
            setSelectedMessageID(id)
        }
    }

    const getSessionId = async () => {
        try {
            const {data} = await httpGetSessionId(uuid)
            LocalStorageManager.sessionId.set(data.session_id)
            const getSessionId = LocalStorageManager.sessionId.get()
            socket.emit('joinRoom', {roomId: `${uuid}:${getSessionId}`});
        } catch (error) {
            handleApiError({error, handleGeneralError: showError})
        }
    }

    useEffect(() => {
        setInterval(() => {
            socket.emit('keepConnection', 'ping');
        }, 10000);
    }, [])

    useEffect(() => {
        socket.on('keepConnection', () => {})
        socket.on('chat-message', (msg) => {
            const {content, status} = msg
            if (chatBodyContainerRef?.current) chatBodyContainerRef.current.scrollIntoView({behavior: "smooth"})

            setChatBotMessages(prevMessages => {
                console.log("chatbot => , ", prevMessages , content )
                return {
                    message: (prevMessages?.message ? prevMessages.message + content : content).replace(null, ''),
                }
            })

            setStatus(status)
        })

    }, [])

    useEffect(() => {
        switch (status) {
            case statusSocket.IN_PROGRESS:
                break;
            case statusSocket.COMPLETED:
                if(lastMessage){
                    setAllMessages(prevState => [
                        {
                            id: `${lastMessage?.id}-bot`,
                            message: chatBotMessages?.message,
                            role: "assistant",
                        },
                        {
                            id: `${lastMessage?.id}-user`,
                            message: lastMessage?.question,
                            role: "user",
                        },
                        ...prevState])
                }
                setDraft(null)
                setChatBotMessages(null)
                setStatus(statusSocket.DEFAULT)
                break;
            case statusSocket.ERROR:
                setErrorTooManyRequests(true)
                triggerAlert({message: 'Something went wrong, try again!', type: 'error'})
                break;
            default:
                break;
        }

    }, [lastMessage, status, draft])

    return <>
        <StyledContainer>
            <Sidebar
                openSideBar={openSideBar}
                dataSideBar={dataSideBar}
                isLoading={isLoading}
                setOpenSideBar={setOpenSideBar}
                setSelectedMessageID={setSelectedMessageID}
                lastSeen={lastSeen}
            />


            <StyledCol>
                <ChatBotBody
                    haveTransition={haveTransition}
                    chatBotMessages={chatBotMessages}
                    allMessages={allMessages}
                    setAllMessages={setAllMessages}
                    setChatBotLoading={setChatBotLoading}
                    chatBotLoading={chatBotLoading}
                    openSideBar={openSideBar}
                    handleOpenSideBarFromInfo={handleOpenSideBarFromInfo}
                    selectedMessageID={selectedMessageID}
                    draft={draft}
                    chatBodyContainerRef={chatBodyContainerRef}
                    errorTooManyRequests={errorTooManyRequests}
                    setErrorTooManyRequests={setErrorTooManyRequests}
                    setDraft={setDraft}
                    setLastMessage={setLastMessage}
                />

                <ChatBotFooter
                    draft={draft}
                    setHaveTransition={setHaveTransition}
                    pendingSocket={status === statusSocket.IN_PROGRESS}
                    haveTransition={haveTransition}
                    setChatBotLoading={setChatBotLoading}
                    chatBotLoading={chatBotLoading}
                    openSideBar={openSideBar}
                    setDraft={setDraft}
                    chatBodyContainerRef={chatBodyContainerRef}
                    setErrorTooManyRequests={setErrorTooManyRequests}
                    setLastMessage={setLastMessage}
                    setAllMessages={setAllMessages}
                />
            </StyledCol>
        </StyledContainer>
    </>
}

export default ChatBot;