import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {StyledTextarea} from "./styles";

const TextArea = forwardRef(function TextArea(
    {
        placeholder,
        name,
        helpText,
        onChange,
        onBlur,
        touched = false,
        errors,
        resetInput,
        disabled,
        onSubmit,
        maxRows = 3,
        ...rest
    },
    forwardedRef
) {
    const textareaRef = useRef()
    const ref = forwardedRef ?? textareaRef
    const [rows, setRows] = useState(1);
    const [text, setText] = useState('');

    const handleKeyDown = (e) => {
        //Enter
        if (!e.shiftKey && e.key === "Enter") {
            e.preventDefault();
            text && onSubmit();
        }
    };

    useEffect(() => {
        if (resetInput) {
            setText('')
            setRows(1)
        }
    }, [resetInput])

    const handleChange = (e) => {
        const value = e.target.value;
        setText(value);
        setRows(value.split('\n').length);
        if (maxRows !== null && value.split('\n').length >= maxRows) {
            setRows(maxRows);
        }
        onChange(e);
    };


    return <StyledTextarea
        name={name}
        placeholder={placeholder}
        ref={ref}
        value={text}
        rows={rows}
        onBlur={onBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        {...rest}
    />
})

export default TextArea;