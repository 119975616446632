import React, {useEffect, useState} from 'react';
import {
    ContainerChatBotLoader,
    StyledContainerButton,
    StyledContainerTextArea,
    StyledFooterContainer
} from "./styles";
import Button from "../../button/Button";
import {SendMessageIcon} from "../../../assets/icons/icons";
import TextArea from "../../textarea/TextArea";
import {useForm, useWatch} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {FOOTER_MODEL, validationSchema} from "./modalFooter";
import {DevTool} from "@hookform/devtools";
import {handleApiError} from "../../../utilities/helpers";
import useError from "../../../hooks/useError";
import {httpFetchResponse} from "../../../http/chatbot";
import ChatBotLoader from "../chatbotLoader/ChatBotLoader";
import {useWindowSize} from "../../../hooks/useWindowSize";
import {useParams} from "react-router-dom";

const ChatBotFooter = ({
                           pendingSocket,
                           setHaveTransition,
                           setChatBotLoading,
                           chatBotLoading,
                           openSideBar,
                           setDraft,
                           chatBodyContainerRef,
                           setErrorTooManyRequests,
                           setLastMessage,
                           setAllMessages,
                           draft,
                       }) => {
    const showError = useError()
    const [resetInput, setResetInput] = useState(false)
    const {width} = useWindowSize()
    const [isMobile, setIsMobile] = useState(width <= 448)
    const {uuid} = useParams()

    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])


    const methods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'onChange',
        nativeValidation: false,
        shouldFocusError: true,
        resolver: yupResolver(validationSchema),
    })

    const {
        register,
        control,
        handleSubmit,
        reset,
        formState: {errors},
    } = methods


    useEffect(() => {
        if (chatBodyContainerRef?.current && !!draft) chatBodyContainerRef.current.scrollIntoView({behavior: "smooth"})
    }, [chatBodyContainerRef, draft])
    const sendMessage = async (values) => {
        setDraft(messageValue)
        reset({message: ''})
        if (chatBodyContainerRef?.current) chatBodyContainerRef.current.scrollIntoView({behavior: "smooth"})
        setResetInput(true)
        setLastMessage(null)
        try {
            setChatBotLoading(true)
            const {data} = await httpFetchResponse(uuid, values.message);
            if (chatBodyContainerRef.current) chatBodyContainerRef.current.scrollIntoView({behavior: "smooth"})
            if (!data?.answer) {
                setChatBotLoading(false)
                setLastMessage({
                    id: data.id,
                    question: data.question,
                    answer: data?.answer,
                })
                setHaveTransition(false)
            } else {
                setAllMessages(prevState => [
                    {
                        id: `${data.id}-bot`,
                        message: data.answer,
                        role: "assistant",
                    },
                    {
                        id: `${data.id}-user`,
                        message: data.question,
                        role: "user",
                    },
                    ...prevState])
                setHaveTransition(true)
                setDraft(null)
            }
        } catch (error) {
            if (error?.response?.status === 500 || error?.response?.status === 429 || error?.response?.status === 502) {
                setErrorTooManyRequests(true)
                showError(`Error 429. Something went wrong, please click the reload button to try again`)
            } else {
                handleApiError({error, handleGeneralError: showError})
            }
        } finally {
            setChatBotLoading(false)
            setResetInput(false);
        }
    }

    const messageValue = useWatch({
        control: control,
        name: FOOTER_MODEL.message,
    })

    const isOnlySpaces = !messageValue || messageValue.trim().length === 0

    return <>
        <StyledFooterContainer
            onSubmit={handleSubmit((values) => sendMessage(values))}>

            <StyledContainerTextArea>
                <TextArea
                    placeholder="Ask something..."
                    name={FOOTER_MODEL.message}
                    {...register(FOOTER_MODEL.message)}
                    errors={errors}
                    resetInput={resetInput}
                    disabled={chatBotLoading || pendingSocket}
                    onSubmit={handleSubmit((values) => sendMessage(values))}
                />
            </StyledContainerTextArea>

            <StyledContainerButton>
                {isMobile && openSideBar ? null :
                    chatBotLoading || pendingSocket ?
                        <ContainerChatBotLoader>
                            <ChatBotLoader colorWhite/>
                        </ContainerChatBotLoader>
                        : <Button
                            variant="round"
                            data="secondary"
                            collapsed
                            size="small"
                            width={40}
                            icon={<SendMessageIcon/>}
                            disabled={isOnlySpaces || chatBotLoading || pendingSocket}
                            type={'submit'}
                        />}
            </StyledContainerButton>
        </StyledFooterContainer>
        {<DevTool control={control}/>}
    </>
}

export default ChatBotFooter;