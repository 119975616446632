import styled, {css} from "styled-components";


export const StyledContainerMessage = styled.div`
  ${({isChatBot}) => {
    return css`
      display: grid;
      grid-template-columns: ${isChatBot ? `18px auto` : `auto 18px`};
      padding: 8px;
      justify-content: ${isChatBot ? 'flex-start' : 'flex-end'};
      align-items: flex-end;
    `;
  }}
`;

export const StyledMessage = styled.div`
  ${({theme, isSelected, isChatBot, errorTooManyRequests, openSideBar}) => {
    return css`
      display: flex;
      background-color: ${!isSelected ? theme.palette.neutral.white : theme.palette.secondary.base.hover};
      padding: 8px 16px;
      border-radius: 20px;
      color: ${!isSelected ? theme.palette.secondary.base.active : theme.palette.neutral.white};
      font-size: 14px;
      line-height: 20px;
      max-width: 750px;
      cursor: default;
      white-space: break-spaces;

      ${!isChatBot && css`
        background-color: ${theme.palette.neutral[100]};
        color: ${theme.palette.secondary.base.active};
      `}
      a {
        color: ${isSelected ? theme.palette.neutral[100] : theme.palette.secondary.base.active};
        text-decoration: underline;
        font-weight: 600;
        cursor: pointer;
      }
      span{
        white-space: break-spaces;
      }
      
      ${errorTooManyRequests && css`
        background-color: ${theme.palette.danger.background};
        color: ${theme.palette.danger.text};
      `}
      
    `;
  }}
`;

export const StyledDot = styled.div`
  ${({theme, isChatBot, isSelected, errorTooManyRequests}) => {
    return css`
      width: 18px;
      height: 18px;
      background-color: ${!isSelected ? theme.palette.neutral.white : theme.palette.secondary.base.hover};
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      ${!isChatBot && css`
        background-color: ${theme.palette.neutral[100]};
      `}
      
      ${errorTooManyRequests && css`
        background-color: ${theme.palette.danger.background};
      `}
    `;
  }}
`;

export const StyledContainerInfoIcon = styled.div`
  ${({theme}) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: -20px;
    margin-top: -12px;
    background: ${theme.palette.neutral.white};
    border-radius: 50px;
  `}
`;

export const StyledContainerMessageError = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  align-self: end;
  svg {
    cursor: pointer;
    animation: spin 1s linear infinite;
    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;


