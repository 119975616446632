import {AlertSideBarIcon, InfoIcon, SegmentsIcon, SourcesIcon} from "../assets/icons/icons";

export default (originalResponse = {}, debugInfo = {}) => {
    const documents = originalResponse?.documents
    const segments = originalResponse?.segments
    return [
        {
            id: 1,
            title: 'Debug info',
            icon: <InfoIcon/>,
            children: [
                {
                    id: 0,
                    description: 'This question is not related to any document, it’s most likely related to the rest of the conversation',
                    icon: <AlertSideBarIcon/>,
                    isAlert: true,
                    isVisible: originalResponse?.is_documentation_related,
                },
                {
                    id: 1,
                    description: debugInfo?.documents_count + ' Sources',
                    icon: <SourcesIcon/>,
                    isAlert: false,
                    isVisible: false,
                },
                {
                    id: 2,
                    description: debugInfo?.segments_count + ' Segments',
                    icon: <SegmentsIcon
                        width={13}
                    />,
                    isAlert: false,
                    isVisible: false,
                }
            ]
        },
        {
            id: 2,
            title: 'Relevant Sources',
            icon: <SourcesIcon/>,
            children: documents?.map((document) => {
                return {
                    id: document?.id,
                    description: document?.name,
                    metadata: document?.metadata,
                    isVisible: false,
                }
            })
        },
        {
            id: 3,
            title: 'Relevant Segments',
            icon: <SegmentsIcon/>,
            isSegment: true,
            haveAlert:  originalResponse?.is_documentation_related,
            children: segments?.map((segment) => {
                return ({
                    id: segment?.id,
                    title: 'Segment content',
                    description: segment?.content,
                    accuracy: Math.floor(segment?.score),
                    selected: segment?.selected,
                    sourceName: segment?.document,
                })
            })
        }
    ]
}
