const neutral = {
    white: '#ffffff',
    50: '#FCFCFC',
    100: '#EAEEF5',
    200: '#FFFFFF99',
    300: '#E5E7EE',
    400: '#CCD0DE',
    500: '#9FA2AE',
    600: '#595959',
    700: '#2E2E2E',
    800: '#1F1F1F',
    900: '#0F0F0F',
    black: '#000000',
    transparent: 'rgba(0, 0, 0, 0)',
}

export const palette = {
    light: {
        neutral,
        primary: {
            light: {
                active: '#FF7A00',
                background: '#FFF3ED',
            },
            base: {
                active: '#F4F5F8',
                text: '#1D0F41',
                hover: '#5F42D9',
                disabled: '#D7B4F1',
                background: '#A944F5',
            },
            dark: {},
        },
        secondary: {
            light: {},
            base: {
                active: '#2B4272',
                text: '#171428',
                hover: '#40465E',
                disabled: '#CCD0DE',
                background: '#2B4272',
            },
            dark: {},
        },
        success: {
            background: '#B7F0CF',
            text: '#6FD285',
            hover: '#40B159',
            disabled: '#C0D9C6',
            active: '#6FD285',
        },
        danger: {
            background: '#F5DCDF',
            text: '#FF0000',
            hover: '#D6183D',
            disabled: '#DD91A0',
            active: '#F23157',
        },
        warning: {
            background: '#FFE8A5',
            text: '#CB9F1D',
            hover: '#EBB000',
            disabled: '#DFC062',
            active: '#FFC107',
        },
    },
    dark: {
        neutral,
        primary: {
            light: {},
            base: {
                background: '#DFD9FA',
                text: '#361BA9',
                hover: '#5F42D9',
                disabled: '#BDB4E7',
                active: '#7F63F4',
            },
            dark: {},
        },
        secondary: {
            light: {},
            base: {
                background: '#9B95C8',
                text: '#171428',
                hover: '#0A0814',
                disabled: '#706C86',
                active: '#171428',
            },
            dark: {},
        },
        success: {
            background: '#B7F0CF',
            text: '#6FD285',
            hover: '#40B159',
            disabled: '#C0D9C6',
            active: '#6FD285',
        },
        danger: {
            background: '#FFABBC',
            text: '#F23157',
            hover: '#D6183D',
            disabled: '#DD91A0',
            active: '#F23157',
        },
        warning: {
            background: '#FFE8A5',
            text: '#CB9F1D',
            hover: '#EBB000',
            disabled: '#DFC062',
            active: '#FFC107',
        },
    },
}

