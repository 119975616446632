import React, {useState} from 'react';
import {StyledContainerHeaderParagraph} from "../paragraph/styles";
import {ExpandIcon} from "../../../assets/icons/icons";
import {
    StyledContainerAccuracy, StyledContainerExpandSvg,
    StyledContainerRelevantSeg, StyledContainerSmall,
    StyledDiv,
    StyledScrollableDiv,
    StyledSegmentData,
    StyledSegments
} from "./styles";

const Segments = ({
                      itemChildren,
                      item,
                  }) => {

    const maxChars = 300; // maximum number of characters to show
    const relevantSegments = itemChildren?.filter((segment) => segment?.selected)
    const irrelevantSegments = itemChildren?.filter((segment) => !segment?.selected)
    const [relevantSegmentsExpand, setRelevantSegmentsExpand] = useState(Array(relevantSegments?.length).fill(false));
    const [irrelevantSegmentExpand, setIrrelevantSegmentExpand] = useState(Array(irrelevantSegments?.length).fill(false));

    const toggleIsExpanded = (index, isRelevant) => {
        if (isRelevant) {
            setRelevantSegmentsExpand(prevState => {
                const newState = [...prevState];
                newState[index] = !prevState[index];
                return newState;
            });
        } else {
            setIrrelevantSegmentExpand(prevState => {
                const newState = [...prevState];
                newState[index] = !prevState[index];
                return newState;
            });
        }
    };

    return <>
        <StyledDiv>
            <StyledScrollableDiv haveAlert={item?.haveAlert}>

                <StyledContainerRelevantSeg>
                    {relevantSegments && relevantSegments.map((item, index) => {
                        const canExpanded = item?.description?.length > 300;
                        return <StyledSegments key={item?.id}>
                            <div>
                                <StyledSegmentData>
                                    {item?.title}
                                    <StyledContainerAccuracy>
                                        <p> {item?.accuracy}% Accuracy</p>
                                    </StyledContainerAccuracy>
                                </StyledSegmentData>
                                <p>
                                    From: {item?.sourceName}
                                </p>
                            </div>

                            <StyledContainerSmall>
                                {canExpanded && <StyledContainerExpandSvg isExpanded={relevantSegmentsExpand[index]}>
                                    <ExpandIcon onClick={() => toggleIsExpanded(index, true)}/>
                                </StyledContainerExpandSvg>}
                                <small>
                                    {relevantSegmentsExpand[index]
                                        ? item?.description
                                        : item?.description.slice(0, maxChars)
                                    }
                                    {!canExpanded || relevantSegmentsExpand[index] ? null : '...'}
                                </small>
                            </StyledContainerSmall>
                        </StyledSegments>
                    })}
                </StyledContainerRelevantSeg>


                <StyledDiv header>
                    <StyledContainerHeaderParagraph notRelevant>
                        <p>Relevant Segment not included</p>
                    </StyledContainerHeaderParagraph>
                </StyledDiv>


                <StyledContainerRelevantSeg>
                    {irrelevantSegments && irrelevantSegments.map((item, index) => {
                        const canExpanded = item?.description?.length > 300;
                        return <StyledSegments
                            key={item?.id}
                            notRelevant
                        >
                            <StyledSegmentData>
                                {item?.title}
                                <StyledContainerAccuracy notRelevant>
                                    <p> {item?.accuracy}% Accuracy</p>
                                </StyledContainerAccuracy>
                            </StyledSegmentData>
                            <p>
                                From: {item?.sourceName}
                            </p>
                            <StyledContainerSmall>
                                {canExpanded && <StyledContainerExpandSvg isExpanded={irrelevantSegmentExpand[index]}>
                                    <ExpandIcon onClick={() => toggleIsExpanded(index, false)}/>
                                </StyledContainerExpandSvg>}
                                <small>
                                    {irrelevantSegmentExpand[index]
                                        ? item?.description
                                        : item?.description.slice(0, maxChars)
                                    }
                                    {!canExpanded || irrelevantSegmentExpand[index] ? null : '...'}
                                </small>
                            </StyledContainerSmall>
                        </StyledSegments>
                    })}
                </StyledContainerRelevantSeg>
            </StyledScrollableDiv>
        </StyledDiv>
    </>
}

export default Segments;