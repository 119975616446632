import axios from './'

export const httpGetSessionId = (uuid) => {
    const url = `/api/domains/${uuid}/start-session`
    return axios.get(url)
}

let counter = 0;
export const httpFetchResponse = (uuid, message) => {
    const url = `/api/domains/${uuid}/conversations`
    return axios.post(url, {question: message})
    counter++;
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const data = {
                "id": 130 + counter,
                "session_id": "0f8da001-2447-424b-ad0d-09e29a5b5809",
                "question": "What if the host asks me to photograph multiple listings at one location?",
                "answer": "Per programmare meglio il tuo lavoro di fotografia e massimizzare la produttività, ti\n" +
                    "di svolgere seguire ogni questi giorno e suggerimenti: assegna 1. un tempo Organizza il specifico tuo o tempo ciascuna Crea di un esse. elenco 2. delle Pianifica in attività anticipo che consigliamo devi\n" +
                    "Programma i servizi fotografici con largo anticipo e cerca di concentrare le attività in determinati\n" +
                    "giorni e orari in base alle tue esigenze personali. Mantieniti aggiornato Rimani sempre\n" +
                    "aggiornato sulle ultime tendenze e tecnologie in ambito fotografico, e aumenta la tua efficienza\n" +
                    "con l'aiuto di programmi informatici appositi. 4. Sii flessibile - Non dimenticare di essere flessibile\n" +
                    "quando programmi i tuoi servizi fotografici, poiché i clienti potrebbero richiederti la disponibilità\n" +
                    "in giorni e orari diversi da quelli da te prefissati. 5. Cura la tua attrezzatura - Assicurati di avere\n" +
                    "tutto il materiale fotografico necessario, e che sia sempre in buono stato, al fine di minimizzare\n" +
                    "imprevisti o perdite di tempo per inconvenienti tecnici. Speriamo che questi suggerimenti t\n" +
                    "possano aiutare a programmare meglio il tuo lavoro di fotografia",
                "created_at": "2023-05-11 13:22:03"
            };
            resolve({data});

        }, 2000);
    });
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const error = {
                "status": 429,
                "message": "Too Many Requests"
            };
            reject(error);
        }, 2000);
    });
};

export const httpGetDebugInfo = (uuid) => {
    const url = `/api/domains/${uuid}`
    return axios.get(url)
}

export const httpGetDataSideBarAnalytics = (uuid, conversationId) => {
    const url = `/api/domains/${uuid}/conversations/${conversationId}`
    return axios.get(url)
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const data = {
                "is_inherent": true,
                "documents": [
                    {
                        "id": 13,
                        "name": "Media Quality",
                        "metadata": {}
                    },
                    {
                        "id": 13,
                        "name": "Media Quality",
                        "metadata": {}
                    },
                    {
                        "id": 14,
                        "name": "Rates and Photoshoot",
                        "metadata": {}
                    },
                    {
                        "id": 13,
                        "name": "Media Quality",
                        "metadata": {}
                    },
                    {
                        "id": 13,
                        "name": "Media Quality",
                        "metadata": {}
                    },
                    {
                        "id": 14,
                        "name": "Rates and Photoshoot",
                        "metadata": {}
                    },
                    {
                        "id": 13,
                        "name": "Media Quality",
                        "metadata": {}
                    },
                    {
                        "id": 13,
                        "name": "Media Quality",
                        "metadata": {}
                    },
                    {
                        "id": 14,
                        "name": "Rates and Photoshoot",
                        "metadata": {}
                    },
                    {
                        "id": 13,
                        "name": "Media Quality",
                        "metadata": {}
                    },
                    {
                        "id": 13,
                        "name": "Media Quality",
                        "metadata": {}
                    },
                    {
                        "id": 14,
                        "name": "Rates and Photoshoot",
                        "metadata": {}
                    }
                ],
                "segments": [
                    {
                        "id": 68,
                        "document": "Account",
                        "content": "How do I update my location?\nThrough the Media Box settings page, you can update your market coverage at any time. Please select as many markets as you’d like to receive assignments in.",
                        "distance": 0.124245762825,
                        "score": 96.73,
                        "selected": true
                    },
                    {
                        "id": 73,
                        "document": "Account",
                        "content": "Can I photograph while traveling?\nYes! Please update your list of available markets on your profile within Media Box. Please don’t forget to remove this market once you’re back to avoid receiving future requests.",
                        "distance": 0.2021048069,
                        "score": 36.84,
                        "selected": true
                    },
                    {
                        "id": 89,
                        "document": "Rates and Photoshoot",
                        "content": "Do you compensate for travel?\nWe sometimes offer travel compensation based on the driving distance between the listing and your registered address. Travel compensation varies from market to market. If travel compensation is offered, it will be visible on the assignment request in Media Box.",
                        "distance": 0.220707416534,
                        "score": 22.53,
                        "selected": true
                    },
                    {
                        "id": 69,
                        "document": "Account",
                        "content": "How are my markets determined?\nYour markets are determined based on the cities (or Airbnb markets) selected during the onboarding process. For example, by selecting \"Paris,\" you will receive requests from the whole Paris market. Note, that ‘markets’ are typically larger than cities. If you receive an assignment within your market that is too far away, you can always decline the assignment in Media Box. You can always update your list of selected markets on the Media Box settings page.",
                        "distance": 0.231931388378,
                        "score": 13.9,
                        "selected": true
                    },
                    {
                        "id": 88,
                        "document": "Rates and Photoshoot",
                        "content": "How are rates determined?\nThe rate is defined by multiple criteria such as the location of the listing, the number of bedrooms and the distance of the listing from your registered address.",
                        "distance": 0.24122685194,
                        "score": 6.75,
                        "selected": false
                    },
                    {
                        "id": 85,
                        "document": "Media Quality",
                        "content": "Where can I find the upload folder on DARYL?\nOnce you are ready to upload your images, you can go to the assignment page on the Media Box, and you will be redirected to the correct DARYL folder. Make sure you are logged in to DARYL. If you still cannot find the folder, reach out to photography@airbnb.com for support.",
                        "distance": 0.242865383625,
                        "score": 5.49,
                        "selected": false
                    },
                    {
                        "id": 86,
                        "document": "Media Quality",
                        "content": "I cannot access DARYL. What should I do?\nFor the first time you upload photos to DARYL, please click the ‘Forgot password’ link. Once you enter your email address, you will then receive an email to create a password. You should then have the ability to access Daryl. Please do not create a new account on DARYL as it’s done automatically when you accept your first assignment. The second time you want to connect to DARYL, use your email address and the password you created. If you still have issues, try to access DARYL using the following link: https://airbnb.orangelogic.com/CS.aspx?VP3=LoginRegistration&L=True&R=False",
                        "distance": 0.244786798954,
                        "score": 4.01,
                        "selected": false
                    },
                    {
                        "id": 72,
                        "document": "Account",
                        "content": "Are there other kinds of assignments besides home photoshoots?\nWithin Media Box, you can now select what kinds of assignments you can receive. There are now a few options available for homes and Experiences.\n\n",
                        "distance": 0.255095005035,
                        "score": 0,
                        "selected": false
                    },
                    {
                        "id": 75,
                        "document": "Host communication",
                        "content": "How do I communicate with the host?\nAfter you have accepted the assignment, you will be connected with the host via email, and we encourage you to communicate through this thread. If you can't find the introduction email named \"Meet your Photographer,\" look for it in your spam folder. The host’s phone number will also be visible on the assignment page within Media Box.",
                        "distance": 0.250007510185,
                        "score": 0,
                        "selected": false
                    },
                    {
                        "id": 83,
                        "document": "Media Quality",
                        "content": "Where can I find the instructions on the photography process?\nFor each new request you will receive on the Media Box, you will find a link to the specific assignment style guide. You can also click this link (https://airbnb.account.box.com/login?redirect_url=%2Ffolder%2F144392846792) for the latest style guides. where you can find all the answers related to our photography process.",
                        "distance": 0.255320966244,
                        "score": 0,
                        "selected": false
                    }
                ]
            };
            resolve({data});
        }, 2000);
    });
}


//TODO: da implementare

export const httpUploadMedia = (formData, mediaType, options) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const data = {
                id: Math.floor(Math.random() * 1000).toString(),
                path_location: 'https://picsum.photos/200/300',
                name: 'image.jpg',
            };
            resolve({data});
        }, 2000);
    });
};