import styled, {css} from "styled-components";


export const StyledWrapperCenter = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  justify-items: center;
  align-items: center;
  max-width: 386px;
  gap: 32px;
`;

export const StyledContainerText = styled.div`
  ${({theme, haveMaxWidth}) => css`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    max-width: ${haveMaxWidth && '288px'};
    ${theme.mediaQueries.mUp} {
      max-width: ${haveMaxWidth && '100%' };
    }
  `}
`;

export const StyledFirstRow = styled.div`
  ${({theme}) => css`
    background-color: ${theme.palette.neutral.white};
  `}
`;

export const StyledFooterHome = styled.div`
  ${({theme}) => css`
    background-color: ${theme.palette.neutral[400]};
    min-height: 44px;
    width: 100%;
  `}
`;

export const StyledCol = styled.div`
  ${({theme}) => css`
    padding: 40px 16px;

    ${theme.mediaQueries.mUp} {
      padding: 14px 0 20px 0;
    }
  `}
`;


