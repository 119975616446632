import {Outlet} from 'react-router-dom'

const PrivateLayout = props => {

    return (
        <Outlet/>
    )
}

export default PrivateLayout
