import styled, {css} from "styled-components";


export const StyledTextarea = styled.textarea`
    ${({theme, disabled}) => {
      return css`
        font-size: 16px;
        line-height: 24px;
        border-radius: 20px;
        resize: none;
        padding: 8px 16px;
        width: 100%;
        background: ${theme.palette.neutral.white};
        &::placeholder {
          color: ${theme.palette.secondary.base.background};
        }
        
        
        ${disabled && css`
          background: ${theme.palette.neutral[100]};
          cursor: not-allowed;
          &::placeholder {
            color: ${theme.palette.neutral[400]};
          }
        `}
  
        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background: ${theme.palette.secondary.base.background};
          border-radius: 0.5rem;
        }
      `;
    }}
`;
