/**
 * Usage:
 *
 * handleApiError({ error, handleGeneralError: showError })
 *
 */

export const handleApiError = ({
                                   error,
                                   handleGeneralError = console.log,
                                   handleFormError = console.log,
                               }) => {
    const { response: { data } = {} } = error
    Object.keys(data).forEach(errorFieldName => {
        const rawErrorValue = data[errorFieldName]
        if (errorFieldName === 'message') {
            // Generic error
            handleGeneralError(rawErrorValue)
        } else {
            // Form field error
            const message = Array.isArray(rawErrorValue)
                ? rawErrorValue.join(' ')
                : rawErrorValue
            handleFormError(errorFieldName, { message })
        }
    })
}

const capitalize = ([first, ...rest]) => {
    return first.toUpperCase() + rest.join('')
}


export const iterateOverTouchedFields = (
    touchedFields,
    formValues,
    excludedKeys = []
) => {
    let dataToSend = {}
    for (const key in touchedFields) {
        if (!excludedKeys.includes(key)) {
            dataToSend[key] = formValues[key]
        }
    }
    return dataToSend
}
