import styled, {css} from "styled-components";

export const StyledContainerHeaderParagraph = styled.div`
  ${({theme, notRelevant}) => css`
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    min-width: 254px;
    height: 29px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    gap: 10px;
    color: ${theme.palette.neutral.white};
    font-weight: ${!notRelevant ? 800 : 700};
    font-size: 14px;
    direction: ltr;
    ${theme.mediaQueries.mUp} {
      min-width: 365px;
    }
  `}
`;


export const StyledUl = styled.ul`
  ${({theme, relevantSources}) => css`
    color: ${theme.palette.neutral.white};
    padding: 0 8px 32px 8px;
    font-weight: 600;
    font-size: 14px;
    overflow-y: auto;
    direction: rtl;
    margin: 10px 0 ;
    height: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.neutral.white};
      border-radius: 54px;
    }

    &::-webkit-scrollbar-track {
      background-color: ${theme.palette.neutral[500]};
      border-radius: 54px;
    }
    
    ${relevantSources && css`
      max-height: 90px;
      padding: 0 8px 0 8px;
    `}
    
  `}
`;

export const StyledLi = styled.li`
    ${({theme, isVisible, isAlert}) => css`
      direction: ltr;
      margin-bottom: 8px;
      grid-template-columns: auto 1fr;
      gap: 10px;
      align-items: center;
      display: ${!isVisible ? 'grid' : 'none'};
      
      ${isAlert && css`
        background-color: ${theme.palette.primary.light.background};
        color: ${theme.palette.secondary.base.active};
        border: 2px solid ${theme.palette.primary.light.active};
        border-radius: 4px;
        padding: 10px;
        align-items: start;
        & svg > path {
          fill: ${theme.palette.secondary.base.active};
          
        }
      `
      }
    `}
    `;