import styled, {css} from "styled-components";
import backKrominLabs from "../../../assets/images/backKrominLabs.png";
import angeliniPharmaBackground from "../../../assets/images/angeliniPharmaBack.svg";


export const ChatBotBodyContainer = styled.div`
  ${({theme, isNotKrominLogo}) => {
    return css`
      display: grid;
      grid-template-columns: 16px 1fr 16px;
      grid-template-rows: 1fr;
      position: relative;
      overflow-y: scroll;
      padding: 16px 0;
      background: url(${isNotKrominLogo ? angeliniPharmaBackground : backKrominLabs}) no-repeat center;
      flex: 1;
      height: 100%;

      ${theme.mediaQueries.mUp} {
        grid-template-columns: 0.1fr 1fr 0.1fr;
      }

    
      &::-webkit-scrollbar {
        width: 0.4rem;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: ${theme.palette.secondary.base.background};
        border-radius: 0.5rem;
      }
    `;
  }}
`;

export const StyledContainerMessageBody = styled.div`
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  flex-direction: column-reverse;
`;