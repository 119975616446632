import React, {useEffect, useState} from 'react';
import {
    StyledContainerArrow,
    StyledContainerH3,
    StyledContainerParagraph,
    StyledWrapperSidebarDesktop,
    StyledWrapperSidebarMobile
} from "./styles";
import Paragraph from "../paragraph/Paragraph";
import {ExpandIcon} from "../../../assets/icons/icons";
import {useWindowSize} from "../../../hooks/useWindowSize";
import Spinner from "../../spinner/Spinner";

const SideBar = ({
                     openSideBar,
                     dataSideBar,
                     isLoading,
                     setOpenSideBar,
                     setSelectedMessageID,
                     lastSeen,
                 }) => {

    const [hideArrow, setHideArrow] = useState(false)
    const {width} = useWindowSize()
    const [isMobile, setIsMobile] = useState(width <= 448)

    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])

    const renderData = () => {
        switch (true) {
            case !openSideBar:
                return null;
            case !!dataSideBar?.length:
                return <StyledContainerParagraph openSideBar={openSideBar}>
                    {dataSideBar?.map((item) => {
                        return <Paragraph
                            key={item.id}
                            item={item}
                        />
                    })}
                </StyledContainerParagraph>
            case isLoading:
                return <Spinner center={false}
                />
            default:
                return <StyledContainerParagraph openSideBar={openSideBar} dataNotExists>
                    <StyledContainerH3>
                        Click an answer to know what was used to generate it
                    </StyledContainerH3>
                </StyledContainerParagraph>
        }
    }

    useEffect(() => {
        if (isMobile) {
            setHideArrow(true)
        } else {
            const timer = setTimeout(() => {
                setHideArrow(true)
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, []);

    const handleSideBarFromArrow = () => {
        if (dataSideBar?.length) {
            setOpenSideBar(!openSideBar)
            if (openSideBar) {
                setSelectedMessageID(null)
            } else {
                setSelectedMessageID(lastSeen)
                document.getElementById(lastSeen)?.scrollIntoView({behavior: "smooth"})
            }
        } else {
            setOpenSideBar(!openSideBar)
        }

    }

    return <>
        {isMobile ?
            <StyledWrapperSidebarMobile openSideBar={openSideBar}>
                {renderData()}
            </StyledWrapperSidebarMobile> :
            <StyledWrapperSidebarDesktop
                dataNotExists={!dataSideBar?.length}
                openSideBar={openSideBar}>
                {renderData()}
            </StyledWrapperSidebarDesktop>
        }

        {hideArrow && <StyledContainerArrow
            isMobile={isMobile}
            onClick={handleSideBarFromArrow}
            openSideBar={openSideBar}
        >
            <ExpandIcon/>
        </StyledContainerArrow>}
    </>
}

export default SideBar;