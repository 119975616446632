import styled, {css} from "styled-components";


export const StyledWrapperHeader = styled.div`
    ${({theme}) => css`
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 14px 14px 20px 14px;
      background-color: ${theme.palette.neutral.white};
      ${theme.mediaQueries.mUp} {
        padding: 14px;
      }
    `}
`;
