import React from 'react';
import {StyledContainerHeaderParagraph, StyledLi, StyledUl} from "./styles";
import Segments from "../segments/Segments";

const Paragraph = ({item}) => {

    const {title, icon, isSegment, children: itemChildren, id} = item
    return <>
        <StyledContainerHeaderParagraph>
            {icon}
            <p>{title}</p>
        </StyledContainerHeaderParagraph>

        {isSegment ?
            <Segments
                itemChildren={itemChildren}
                item={item}
            />
            :
            <StyledUl relevantSources={id === 2}>
                {itemChildren && itemChildren.map(({id, icon, description, isVisible, isAlert}, index) => {
                    return <StyledLi
                        key={id}
                        isVisible={isVisible}
                        isAlert={isAlert}
                    >
                        {icon ? icon : index + 1 + '. '}
                        {description}
                    </StyledLi>
                })}
            </StyledUl>
        }
    </>
}

export default Paragraph;