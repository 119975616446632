import styled, {css} from "styled-components";

export const StyledContainerAlert = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1301;
`;


export const StyledRoot = styled.div`
  ${({theme, variantStyle, visible}) => css`
    pointer-events: auto;
    margin: 20px;
    padding: 16px;
    max-width: 1156px;
    color: ${variantStyle.color};
    background-color: ${variantStyle.backgroundColor};
    border-radius: 60px;
    transition: transform 0.4s ease-in-out;
    will-change: transform;

    ${theme.mediaQueries.mUp} {
      margin: 20px auto 16px auto;
      right: auto;
      left: 50%;
      padding: 16px;
      max-width: min-content;
      min-width: 490px;
    }

    ${visible && css`
      display: block;
      transform: translateY(0);

      ${theme.mediaQueries.mUp} {
        transform: translateY(calc(0));
      }
    `}

    ${!visible && css`
      display: none;
      transform: translateY(-1000%);

      ${theme.mediaQueries.mUp} {
        transform: translateY(-1000%);
      }
    `}

  `}
`;

export const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  text-align: center;
`

export const StyledContent = styled.div`
  grid-column-start: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StyledTitle = styled.span`
  font-size: 14px;
  line-height: 20px;
`

export const StyledMessage = styled.span`
  word-break: break-word;
`

export const StyledIcon = styled.span`
  display: flex;
  margin-right: 16px;
`

export const StyledCloseIconWrapper = styled.span`
  ${({variantStyle}) => css`
    grid-column-start: 3;

    & svg {
      max-height: 20px;

      & path {
        fill: ${variantStyle.closeIconColor};
      }
    }
  `}
`
