import {css} from "styled-components";
import '@fontsource/mulish/200.css'
import '@fontsource/mulish/300.css'
import '@fontsource/mulish/400.css'
import '@fontsource/mulish/500.css'
import '@fontsource/mulish/600.css'
import '@fontsource/mulish/700.css'
import '@fontsource/mulish/800.css'
import '@fontsource/mulish/900.css'


const defaultFontFamily = `'Mulish', sans-serif`
const secondaryFontFamily = `'Roboto', sans-serif`

export const typography = {
    heading1: css`
      font-family: ${defaultFontFamily};
      font-weight: 700;
      font-size: 2.5rem;
      line-height: 3.5rem;
      letter-spacing: -0.5px;
    `,
    heading2: css`
      font-family: ${defaultFontFamily};
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.5rem;
      letter-spacing: -0.5px;
    `,
    heading3: css`
      font-family: ${defaultFontFamily};
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: -0.5px;
    `,
    heading4: css`
      font-family: ${defaultFontFamily};
      font-weight: 700;
      font-size: 1.25rem; // 20px
      line-height: 150%; 
      letter-spacing: 0.0625rem; // 1px
    `,

    heading5: css`
      font-family: ${defaultFontFamily};
      font-weight: 700;
      font-size: 1.125rem; //18px
      line-height: 2.5rem; //40px
      letter-spacing: 1px;
    `,
    heading6: css`
      font-family: ${defaultFontFamily};
      font-weight: 700;
      font-size: 0.875rem; //14px
      line-height: 1.25rem; 
      letter-spacing: 1px;
    `,

    paragraph: css`
      font-family: ${secondaryFontFamily};
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: -0.5px;
    `,

    small: css`
      font-family: ${defaultFontFamily};
      font-weight: 400;
      font-size: 0.875rem; 
      line-height: 1.375rem;
      letter-spacing: 1px;
    `
}
