import styled, {css} from "styled-components";


export const StyledRootSvg = styled.svg`
    ${({theme, size, center, overlayFullscreen}) => css`
      z-index: 14;
      width: ${size}px;
      height: auto;
      animation: rotate 2s linear infinite;

      & circle {
        animation: dash 1.5s ease-in-out infinite;
      }

      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes dash {
        0% {
          stroke-dasharray: 1, 150;
          stroke-dashoffset: 0;
        }
        50% {
          stroke-dasharray: 90, 150;
          stroke-dashoffset: -35;
        }
        100% {
          stroke-dasharray: 90, 150;
          stroke-dashoffset: -124;
        }
      }

      ${center && css`
        position: ${overlayFullscreen ? 'fixed' : 'absolute'};
        left: 50%;
        margin-left: ${-size / 2}px;
        top: 50%;
        margin-top: ${-size / 2}px;
      `}
    `}
`;


export const StyledOverlay = styled.div`
    ${({theme, overlayFullscreen}) => css`
        z-index: 13;
        position: ${overlayFullscreen ? 'fixed' : 'absolute'};
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: ${overlayFullscreen && theme.palette.secondary.base.hover} ;
    `}
`;
