import React from 'react'
import {StyledOverlay, StyledRootSvg} from "./styles";

const Spinner = ({
                     size = 50,
                     color = '#fff',
                     strokeLineCap = 'square',
                     overlay = true,
                     overlayFullscreen = false,
                     center = true,
                     ...props
                 }) => {
    return (
        <>
            {overlay && <StyledOverlay/>}
            <StyledRootSvg
                center={center}
                size={size}
                overlayFullscreen={overlayFullscreen}
                viewBox="0 0 50 50"
                {...props}
            >
                <circle
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    stroke={color}
                    strokeWidth="3"
                    strokeLinecap={strokeLineCap}
                />
            </StyledRootSvg>
        </>
    )
}

export default Spinner
