import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow-y: hidden;
  width: auto;
`;
export const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;