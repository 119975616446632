import * as yup from 'yup'

const FOOTER_MODEL = {
    message: 'message',
}

const DEFAULT_FOOTER_MOCK = {
    [FOOTER_MODEL.message]: '',
}

const RequestValidationSchema = yup.object().shape({
    [FOOTER_MODEL.message]: yup
        .string()

})

export {
    FOOTER_MODEL,
    DEFAULT_FOOTER_MOCK,
    RequestValidationSchema as validationSchema,
}
