import styled, {css} from "styled-components";


export const StyledFooterContainer = styled.form`
  ${({theme}) => css`
    background-color: ${theme.palette.neutral[400]};
    display: grid;
    grid-template-columns: 16px 1fr 40px 16px;
    grid-template-rows: 1fr;
    align-items: center;
    gap: 8px;
    padding: 16px 0;

    ${theme.mediaQueries.lUp} {
      grid-template-columns: 112px 1fr 40px 112px;
      gap: 12px;
      padding: 25px 0;
    }
  `}
`;

export const StyledContainerTextArea = styled.div`
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
`;


export const StyledContainerButton = styled.div`
  grid-area: 1 / 3 / 2 / 4;
`;

export const ContainerChatBotLoader = styled.div`
  ${({theme}) => css`
    width: 38px;
    height: 38px;
    background-color: ${theme.palette.secondary.base.background};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;