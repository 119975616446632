import React, {useEffect, useMemo, useState} from 'react';
import {StyledContainerInfoIcon, StyledContainerMessage, StyledDot, StyledMessage} from "./styles";
import {InfoIcon} from "../../../assets/icons/icons";

const ChatBotMessage = ({
                            haveTransition,
                            message,
                            isChatBotAnswer = true,
                            openSideBar,
                            handleOpenSideBarFromInfo,
                            id,
                            selectedMessageID,
                            chatBodyContainerRef,
                            errorTooManyRequests
                        }) => {

    const [currentText, setCurrentText] = useState(message);
    const [index, setIndex] = useState(0);

    useEffect(() => {

        if (haveTransition) {
            const intervalId = setInterval(() => {
                setIndex(index => index + 1);
            }, 8);
            if (currentText?.length === message?.length) {
                clearInterval(intervalId);
            }
            return () => clearInterval(intervalId);

        }
    }, [currentText?.length, message?.length]);


    useEffect(() => {
        const checkMessage = (text) => {
            const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|(?:^|[^@\w])([\w.\-]+@[\w\-]+(?:\.[\w\-]+)+)(?:$|[^\w@])/g;
            return text?.match(urlRegex);
        }
        if (checkMessage(message)) {
            const url = checkMessage(message)[0];
            const text = message?.replace(url, `<a href="${url}" target="_blank">${url}</a>`);
            setCurrentText(text);
            return;
        }

        if (haveTransition){
            setCurrentText(message?.substring(0, index));
        } else {
            setCurrentText(message);
        }
        if (chatBodyContainerRef?.current) {
            chatBodyContainerRef.current.scrollIntoView({behavior: "smooth"})
        }
    }, [message, index]);


    const isSelected = useMemo(() => {
        return selectedMessageID === id;
    }, [selectedMessageID, id]);

    return <>
        {!isChatBotAnswer ?
            //user question
            <StyledContainerMessage>
                <StyledMessage errorTooManyRequests={errorTooManyRequests}>
                    {message}
                </StyledMessage>
                <StyledDot errorTooManyRequests={errorTooManyRequests}/>
            </StyledContainerMessage>
            :
            //chatbot answer
            <StyledContainerMessage
                id={id}
                isChatBot
                openSideBar={openSideBar}
            >
                <StyledDot
                    isChatBot
                    openSideBar={openSideBar}
                    isSelected={isSelected}
                />
                <StyledMessage
                    isChatBot
                    openSideBar={openSideBar}
                    isSelected={isSelected}
                >
                    <p>
                        <span dangerouslySetInnerHTML={{__html: currentText}}/>
                    </p>
                    <StyledContainerInfoIcon
                        openSideBar={openSideBar}
                    >
                        <InfoIcon
                            fill={'#2B4272'}
                            onClick={() => handleOpenSideBarFromInfo(id)}
                        />
                    </StyledContainerInfoIcon>
                </StyledMessage>
            </StyledContainerMessage>
        }
    </>
}

export default ChatBotMessage;